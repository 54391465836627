:root {
    --primary-accent: #1890ff;
    --secondary-accent: #ff7830;
    --off-accent: #ffda30;
    --border-radius: 10px;

    --mobile-page-margin: 2.5rem;
    --mobile-nav-element-width: 40vw;
}

[data-theme='DARK'] {
    --primary-text: #fff;

    --card-background: rgba(22, 22, 22, 70%);
    --accent-background: #161616;
    --main-background: #222222;
    --secondary-background: #2a2a2a;
    --box-shadow: #000;
    --grayscale: grayscale(100%);
}
[data-theme='LIGHT'] {
    --primary-text: #0b0b0b;

    --card-background: rgb(255 255 255 / 70%);
    --accent-background: #fff;
    --main-background: darkgray;
    --secondary-background: #dfdfdf;
    --box-shadow: #000;
    --grayscale: grayscale(0%);
}
