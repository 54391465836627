$asset: '../../assets/mountains.jpg';
$ideas: '../../assets/ideas.jpeg';
$laptop: '../../assets/laptop.jpeg';

.About {
    height: 100%;
    padding: var(--page-margin);
    background-image: url($laptop);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    padding: 5rem;

    h1 {
        font-size: 2rem;
        font-weight: bold;
        color: var(--primary-text);
        line-height: 38px;

        span {
            color: var(--primary-accent);
        }
    }

    p {
        font-size: 1.2rem;
        margin: 1rem 0;
        line-height: 26px;
        color: var(--primary-text);

        a {
            color: var(--primary-accent);

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .social {
        margin: 3rem 0 2rem 0;
        display: flex;
        grid-gap: 0.5rem;
        width: 70%;
        max-width: 15rem;
        justify-content: space-between;

        svg {
            font-size: 2rem;
            // opacity: 0.5;
            color: var(--primary-text);

            &:hover {
                cursor: pointer;
                color: var(--primary-accent);
                opacity: 1;
            }
        }
    }

    .contactButton {
        max-width: 15rem;
        width: 70%;
        height: 3rem;
        font-size: 1.5rem;
    }

    @media screen and (max-width: 950px) {
        position: static;
        width: 100%;
        padding-left: 65px;
    }

    @media screen and (max-width: 768px) {
        padding: var(--mobile-page-margin);

        .contactButton {
            width: 100%;
        }
    }
}
