@import '../../styles/mobile-page-styles.scss';

.Skills {
    min-height: 100vh;
    padding: 5rem;
    background-color: var(--secondary-background);
    border-top: 4px solid var(--main-background);

    h1 {
        font-weight: bold;
        color: var(--secondary-accent);
        font-size: 3rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.3rem;

        svg {
            font-size: 2rem;
            margin: 0 0.4rem;
        }
    }

    hr {
        max-width: 360px;
        margin-left: 0;
    }

    .skillSector {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        h2 {
            color: var(--primary-accent);
            font-size: 1.4rem;
            font-weight: bold;
        }

        p {
            font-style: italic;
            color: lightgray;
            font-size: 0.9rem;
            margin-top: 1rem;
            letter-spacing: 2px;
        }

        .listWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 1rem;

            @media screen and (max-width: 1050px) {
                grid-template-columns: 1fr;
            }
        }

        h3 {
            color: var(--primary-accent);
            font-size: 1.2rem;
            font-weight: bold;
            margin: 1rem 0;
        }

        ul {
            list-style: initial;
            color: var(--primary-text);

            li {
                margin: 1rem 0;
                margin-left: 1rem;
            }
        }

        @media screen and (max-width: 1100px) {
            grid-template-columns: 1fr;
            height: unset;
        }
    }
}
