.Hamburger {
    width: 26px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 30px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: var(--primary-accent);
        border-radius: 6px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
        }
        &:nth-child(2) {
            top: 8px;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: 16px;
            transform-origin: left center;
        }
    }

    &.open {
        span:nth-child(1) {
            transform: rotate(45deg);
            top: -1px;
            left: 8px;
        }
        span:nth-child(2) {
            width: 0%;
            opacity: 0;
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
            top: 17px;
            left: 8px;
        }
    }
}
