.ProjectBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    color: white;

    p {
        font-size: 1.2rem;
        line-height: 26px;
        color: var(--primary-text);
    }
    .zoomOverImage {
        text-align: center;

        img {
            transition: transform 0.5s ease;
        }
    }

    .carouselItem {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer {
        display: flex;
        justify-content: space-between;

        .techStack {
            display: flex;
            grid-gap: 1rem;
            font-size: 1.8rem;

            svg:hover {
                color: var(--primary-accent);
            }
        }

        a {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 768px) {
        .footer {
            display: grid;
            grid-template-columns: 50% 2rem;

            .techStack {
                display: grid;
                grid-template-areas:
                    '. . . .'
                    '. . . .'
                    '. . . .';
            }
        }
    }
}
