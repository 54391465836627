@import '../../styles/mobile-page-styles.scss';

.Projects {
    min-height: 100vh;
    padding: 5rem;
    background-color: var(--secondary-background);
    border-top: 4px solid var(--main-background);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
        font-weight: bold;
        color: var(--secondary-accent);
        font-size: 3rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.3rem;
        margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 768px) {
        @include mobilePagePadding;
    }
}
