@import '../../styles/mobile-page-styles.scss';

.Career {
    min-height: 100vh;
    padding: 5rem;
    background-color: var(--secondary-background);
    border-top: 4px solid var(--main-background);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
        color: var(--secondary-accent);
        font-size: 3rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.3rem;
        margin-bottom: 0.5rem;
    }

    .job {
        font-weight: bold;
        color: var(--primary-accent);
        font-size: 1.5rem;
    }

    .duration {
        font-size: 1rem;
        font-weight: bold;
        color: var(--primary-text);
        font-style: italic;
    }

    :global(.ant-timeline-item-tail) {
        border-left: 0.6rem solid var(--accent-background);
        margin-left: 0.2rem;
    }

    :global(.ant-timeline-item-head) {
        height: 1.5rem;
        width: 1.5rem;
        border-color: var(--off-accent);
        background-color: var(--off-accent);
    }

    :global(.ant-timeline-item-content) {
        margin-left: 3rem;
        display: flex;
        flex-direction: column;

        .timelineItem {
            display: flex;
            align-items: center;

            svg {
                margin-left: 1rem;
                font-size: 1.5rem;
                color: var(--primary-text);
            }
        }
    }

    @media screen and (max-width: 768px) {
        @include mobilePagePadding;

        .job {
            font-size: 1rem;
        }

        .duration {
            font-size: 0.8rem;
        }

        .timelineItem {
            justify-content: space-between;
        }
    }
}

.projectBlock {
    margin: 1rem;
    display: flex;
    flex-direction: column;

    h4 {
        color: var(--primary-text);
        font-weight: bold;
        text-decoration: underline;
        font-weight: bold;
    }

    .info {
        margin-top: 0.5rem;
        color: var(--charcoal);
        font-style: italic;
        font-weight: 500;
        opacity: 0.75;
    }
}
