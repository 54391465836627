@import './reset.scss';
@import './theme.scss';
@import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
}

#root {
    height: 100vh;
}

.ant-drawer-mask {
    opacity: 0 !important;
}

.ant-drawer-open.mobileNavDrawer {
    height: calc(100% - 5rem) !important;

    .ant-drawer-content-wrapper {
        height: 100% !important;

        .ant-drawer-body {
            background-color: var(--main-background);
            color: var(--primary-text);
        }
    }
}

main {
    height: inherit;
    display: grid;
    grid-template-columns: 15rem 1fr;
    grid-template-rows: 100vh;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 5rem 1fr;
    }
}

a {
    color: unset;
}

.resumeModal {
    // background-color: white;
    padding-bottom: 0;

    svg {
        color: var(--primary-text);
    }

    .ant-modal-header {
        background-color: var(--main-background);
        .ant-modal-title {
            color: var(--primary-text);
        }
    }

    .ant-modal-body {
        background-color: var(--main-background);
        color: var(--primary-text);
    }

    img {
        width: 100%;
    }
}

.ant-popover {
    max-width: 65%;
    border-radius: var(--border-radius);

    .ant-popover-arrow > span {
        background-color: var(--accent-background);
    }
    .ant-popover-inner {
        border-radius: var(--border-radius);
        background-color: var(--accent-background);

        .ant-popover-title,
        .ant-popover-inner-content {
            color: var(--primary-text);
            padding: 1rem;
            font-weight: bold;
        }
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 0 1rem;

        .ant-popover-inner-content {
            max-height: 50vh;
            overflow: auto;
        }
    }
}
